import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
  QueryDefinition,
  createApi,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";
import { LazyQueryTrigger } from "@reduxjs/toolkit/dist/query/react/buildHooks";
import { RootState } from "../store";
import { ShellConfigApiParams, ShellConfigApiResponse } from "../reducers/model/shellConfig";

export const shellConfigApi = createApi({
  reducerPath: "shellConfig",
  baseQuery: fetchBaseQuery({
    prepareHeaders: (headers, { getState }) => {
      const { user, configuration } = (getState() as RootState).boot;

      if (user && user.access_token) {
        headers.set("Authorization", `Bearer ${user.access_token}`);
        headers.set("x-mashery-domain", configuration.requestUrlBase);
        headers.set("Content-Type", "application/json");
      }

      return headers;
    },
  }),
  // keep data for 1 hour
  keepUnusedDataFor: 3600,
  endpoints: (builder) => ({
    getShellConfig: builder.query<ShellConfigApiResponse, ShellConfigApiParams>({
      query: (params) => {
        const { baseUrl, affAbo, period, selectedIncentive = 0, faaWindow = 12, optionalPeriodRange } = params;

        let url = `${baseUrl}/coreplusConfig/${affAbo}/${period}/${selectedIncentive}?faaEligibilityWindow=${faaWindow}`;

        if (optionalPeriodRange) {
          url += `&optionalPeriodRange=${optionalPeriodRange}`;
        }

        return url;
      },
    }),
  }),
});

export type LazyGetShellConfigQueryType = LazyQueryTrigger<
  QueryDefinition<
    ShellConfigApiParams,
    BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, object, FetchBaseQueryMeta>,
    never,
    ShellConfigApiResponse,
    "shellConfig"
  >
>;

export const { useGetShellConfigQuery, useLazyGetShellConfigQuery } = shellConfigApi;
