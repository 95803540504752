import { useState, useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./MonthlyPerformanceTile.scss";
import { useTranslation } from "react-i18next";
import { formatAboPV } from "@amwaycommon/acc-mixins/l10n";
import { IconTable } from "../../../../common/icons/IconTable/IconTable";
import { MonthlyPerformanceDashData } from "../../../../common/interfaces/monthlyPerformanceDash";
import LeafTile from "../../../../common/leaf/LeafTile/LeafTile";
import defaultContent from "../../../../content/monthlyPerformanceDashboard";
import { storedLocale } from "../../../impersonation/util";
import { useAppSelector } from "../../../../store";
import { ErrorTile } from "../../../../common/components/ErrorTile/ErrorTile";
import MonthlyPerformanceInfoModal from "../MonthlyPerformanceInfoModal/MonthlyPerformanceInfoModal";
import { addParams } from "../../../../common/util/addParams";
import { LeafIconButton } from "../../../../common/leaf/LeafIconButton/LeafIconButton";
import { PerformanceBonusLevelText } from "./PerformanceBonusLevelText/PerformanceBonusLevelText";
import { MonthlyPerformanceTileLoader } from "./MonthlyPerformanceTileLoader/MonthlyPerformanceTileLoader";
import PerformanceBonusScheduleModal from "./PerformanceBonusScheduleModal/PerformanceBonusScheduleModal";
import { MonthlyPerformanceProgressBar } from "./MonthlyPerformanceProgressBar/MonthlyPerformanceProgressBar";

type Props = {
  showInfoModal: boolean;
  handleModalClose: Function;
  monthlyPerformanceData: MonthlyPerformanceDashData;
  isLoading: boolean;
  errorStatus: boolean;
  clickFunction: Function;
};

export const MonthlyPerformanceTile = ({
  showInfoModal,
  handleModalClose,
  monthlyPerformanceData,
  isLoading,
  errorStatus,
  clickFunction,
}: Props) => {
  const { t } = useTranslation(["monthlyPerformanceDashboard"]);
  const locale = storedLocale();
  const { user } = useAppSelector((state) => state.boot);
  const market = user.isoCountryCode;
  const { pvTargetWithOneQleg } = useAppSelector(
    (state) => state.boot.configuration.monthlyDashboard.monthlyPerformance,
  );

  const {
    CorePlanQualified,
    GroupPv,
    CurrentPerformanceBonusLevel,
    Schedules: { bonusDetails = [] },
  } = monthlyPerformanceData;

  const formattedGroupPV = formatAboPV(GroupPv, locale, market);
  const gpvString = `${t("gpv", defaultContent["gpv"])}`;
  const maxPerfBonusPercent = monthlyPerformanceData.Schedules.bonusDetails[0].Percent;
  const maxPerfGpvTarget = formatAboPV(monthlyPerformanceData.Schedules.bonusDetails[0].Volume, locale, market);
  const pvTartgetOneLeg = formatAboPV(pvTargetWithOneQleg, locale, market);
  const [showPerformanceModal, setShowPerformanceModal] = useState(false);
  const prevOpenRef = useRef(false);
  const performanceModalButton = useRef<HTMLButtonElement>(null);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const performanceModalParam = queryParams.get("performanceModal") === "true";

  useEffect(() => {
    if (performanceModalParam && !showPerformanceModal) {
      handleIconTableClose();
    }
  }, [performanceModalParam, showPerformanceModal]);

  useEffect(() => {
    if (!!prevOpenRef.current && !showPerformanceModal && performanceModalButton && performanceModalButton.current) {
      performanceModalButton.current.focus();
    }
    prevOpenRef.current = showPerformanceModal;
  }, [showPerformanceModal]);

  const handleIconTableClick = () => {
    setShowPerformanceModal(true);
    addParams("performanceModal", "true", true, false);
  };

  const handleIconTableClose = () => {
    setShowPerformanceModal(false);
    addParams("performanceModal", "false", false, true);
  };

  const renderMonthlyPerformanceTile = () => {
    if (isLoading) {
      return <MonthlyPerformanceTileLoader isLoading={isLoading} />;
    }
    if (errorStatus) {
      return (
        <ErrorTile
          clickFunction={clickFunction}
          errorStatus={errorStatus}
          isLoading={isLoading}
          tealiumTrackId="Monthly_Performance_Tile"
        />
      );
    }

    return (
      <section>
        <div className="Mpd__section-title">
          <h2>{t("monthlyPerformance", defaultContent["monthlyPerformance"])}</h2>
          <LeafIconButton
            Icon={<IconTable />}
            HoverIcon={<IconTable color="blue" />}
            aria-label={`${t("performanceBonusScheduleButton", defaultContent["performanceBonusScheduleButton"], {
              ns: "common",
            })}`}
            className="Mpd__section-title-button"
            onClick={handleIconTableClick}
            id="mpdIconTableButton"
          />
        </div>
        <p className={"Mpd-Tile__Gpv-value"}>
          {formattedGroupPV} {gpvString}
        </p>
        <PerformanceBonusLevelText
          corePlanQualified={CorePlanQualified}
          currentBonusAmount={CurrentPerformanceBonusLevel}
        />
        <MonthlyPerformanceProgressBar monthlyPerformanceData={monthlyPerformanceData} />
      </section>
    );
  };

  return (
    <div>
      <LeafTile>{renderMonthlyPerformanceTile()}</LeafTile>
      <MonthlyPerformanceInfoModal
        maxPerfBonusPercent={maxPerfBonusPercent}
        maxPerfGpvTarget={maxPerfGpvTarget}
        pvTargetWithOneQleg={pvTartgetOneLeg}
        title={`${t("infoDrawerHeader", defaultContent["infoDrawerHeader"])}`}
        show={showInfoModal}
        isLoading={isLoading}
        onCloseClick={handleModalClose}
        id={"monthly-performance-info"}
        lockBackgroundScrolling={true}
      />
      <PerformanceBonusScheduleModal
        showPerformanceModal={showPerformanceModal}
        handleModalClose={handleIconTableClose}
        bonusDetails={bonusDetails}
      />
    </div>
  );
};
