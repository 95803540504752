import { useTranslation } from "react-i18next";
import { BADGE_SIZE, BADGE_TYPE, IconShield } from "../../../../common/icons/IconShield/IconShield";
import { LeafModal } from "../../../../common/leaf/LeafModal/LeafModal";
import { QUALIFICATION_STATUS } from "../../../../common/enums/incentive-enums";
import { storedLocale } from "../../../impersonation/util";
import defaultContent from "../../../../content/annualPGG";
import defaultCommonContent from "../../../../content/common";
import { HistoryDetail, MonthDetail } from "../../../../common/interfaces/groupGrowthIncentives";
import "./PGGPerformanceBonus.scss";
import LeafRectangleLoader from "../../../../common/leaf/LeafLoadingSkeleton/LeafRectangleLoader/LeafRectangleLoader";
import { ErrorTile } from "../../../../common/components/ErrorTile/ErrorTile";
import { getLongPeriodFormat, getShortMonth, getYear } from "../../../../common/util/period";

type Props = {
  modalIsOpen: boolean;
  toggleModal: Function;
  history: HistoryDetail[];
  isLoading: boolean;
  errorStatus: boolean;
  refetch: Function;
};

export const PGGPerformanceBonus = ({ modalIsOpen, toggleModal, history, isLoading, errorStatus, refetch }: Props) => {
  const { t } = useTranslation(["annualPGG", "common"]);
  const locale = storedLocale();
  const splitHistoryByYear: { [key: string]: any } = {};

  for (const year of history) {
    for (const month of year.Periods) {
      const year = month.Period.toString().slice(0, 4);
      splitHistoryByYear[year] = splitHistoryByYear[year] ? [...splitHistoryByYear[year], month] : [month];
    }
  }

  const years = Object.keys(splitHistoryByYear);

  const getBadgeType = ({ Status }: MonthDetail) => {
    switch (Status) {
      case QUALIFICATION_STATUS.QUALIFIED:
      case QUALIFICATION_STATUS.TRACKING:
        return BADGE_TYPE.EARNED;
      case QUALIFICATION_STATUS.NOT_QUALIFIED:
      case QUALIFICATION_STATUS.HELD:
      case QUALIFICATION_STATUS.DENIED:
        return BADGE_TYPE.NOT_EARNED;
      case QUALIFICATION_STATUS.OPEN:
        return BADGE_TYPE.POTENTIAL;
      default:
        return BADGE_TYPE.UNAVAILABLE;
    }
  };

  const renderLoadingOrError = () => {
    if (isLoading) {
      return (
        <LeafRectangleLoader isLoading={isLoading} numRows={2} width="100%" height="6rem" margin={"1rem 0 0.5rem 0"} />
      );
    }
    if (errorStatus) {
      return (
        <ErrorTile
          clickFunction={refetch}
          errorStatus={errorStatus}
          isLoading={isLoading}
          tealiumTrackId="Performance_Bonus_Modal"
        />
      );
    }
    return <></>;
  };

  const BuildIcons = ({ history }: { history: MonthDetail[] }) => {
    return (
      <div className="PGGPerformanceBonusModal__section">
        <p className="PGGPerformanceBonusModal__section__title">{getYear(history[0].Period, locale)}</p>
        {history.map((month) => {
          return (
            <div
              className="PGGPerformanceBonusModal__shield"
              key={month.Period}
              data-testid={`badge-icon-${getBadgeType(month)}`}
            >
              <IconShield type={getBadgeType(month)} size={BADGE_SIZE.LARGE} />
              <p className="PGGPerformanceBonusModal__shield-month">{getShortMonth(month.Period, locale)}</p>
              <p className="PGGPerformanceBonusModal__shield-amount">{month.BonusAmount}</p>
            </div>
          );
        })}
      </div>
    );
  };
  return (
    <div className="PGGPerformanceBonusModal">
      <LeafModal
        open={modalIsOpen}
        onCloseCallbackFn={toggleModal}
        title={t("incentiveCalc.incentive.base", defaultContent.incentiveCalc.incentive.base).toString()}
      >
        {isLoading && errorStatus ? (
          <>{renderLoadingOrError()}</>
        ) : (
          <div>
            <div className="PGGPerformanceBonusModal__badge">
              {[BADGE_TYPE.EARNED, BADGE_TYPE.POTENTIAL, BADGE_TYPE.NOT_EARNED, BADGE_TYPE.UNAVAILABLE].map(
                (type, index) => {
                  return (
                    <div key={type} className={`PGGPerformanceBonusModal__badge__icon badge-${index + 1}`}>
                      <IconShield type={type} />
                      <span className="PGGPerformanceBonusModal__badge__label">{`${t(type, defaultCommonContent[type], {
                        ns: "common",
                      })}`}</span>
                    </div>
                  );
                },
              )}
            </div>
            <div className="PGGPerformanceBonusModal__badge-group">
              {years.map((year) => (
                <BuildIcons history={splitHistoryByYear[year]} key={year} />
              ))}
            </div>
          </div>
        )}
      </LeafModal>
    </div>
  );
};
