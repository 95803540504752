import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { track } from "@amwaycommon/acc-mixins/src/modules/tealium";
import TEALIUM from "../../common/enums/tealium-enums";
import { useAppDispatch, useAppSelector } from "../../store";
import defaultContent from "../../content/ssi";
import { SELECTOR_TYPE, updateSelectorType } from "../../reducers/period";
import { BonusInfoFooter } from "../../common/components/BonusInfoFooter/BonusInfoFooter";
import { PageTitleSection } from "../../common/components/PageTitleSection/PageTitleSection";
import { RequirementTileMapper } from "../../common/components/RequirementTileMapper/RequirementTileMapper";
import TrackingProgressTile from "../../common/components/TrackingProgressTile/TrackingProgressTile";
import LeafTile from "../../common/leaf/LeafTile/LeafTile";
import { ErrorTile } from "../../common/components/ErrorTile/ErrorTile";
import { ERROR_TYPE, ErrorPage } from "../ErrorPage/ErrorPage";
import { SSIBannerTile } from "./SSIBannerTile/SSIBannerTile";
import SSIEligibilityModal from "./SSIEligibilityModal/SSIEligibilityModal";
import { FetchSsiOverviewData } from "./StrongStartDataLayer";
import "./StrongStart.scss";

export const StrongStart = () => {
  const { t } = useTranslation(["ssi"]);
  const { selectedPeriod } = useAppSelector((state) => state.period);

  const { ssiOverviewData, isLoading, errorStatus, refetch: refetchSsiData } = FetchSsiOverviewData(selectedPeriod);
  const [showEligiblityModal, setShowEligibilityModal] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(updateSelectorType(SELECTOR_TYPE.MONTHLY));
  }, [dispatch]);

  const openEligibilityWindow = () => {
    setShowEligibilityModal(true);
    track(
      TEALIUM.U_TAG_METHOD.LINK,
      TEALIUM.EVENT_NAME.CLICK_ACTION,
      "tracking_tile",
      TEALIUM.CATEGORY.STRONG_START_INCENTIVE,
      "strong_start_incentive",
      "eligibility_modal_button",
    );
  };

  const closeEligibilityWindow = () => {
    setShowEligibilityModal(false);
  };

  useEffect(() => {
    track(
      TEALIUM.U_TAG_METHOD.VIEW,
      "",
      "strong_start_incentive",
      TEALIUM.CATEGORY.STRONG_START_INCENTIVE,
      `period_selected${selectedPeriod}`,
      "",
    );
  }, [selectedPeriod]);

  const displayFullPage = isLoading ? true : ssiOverviewData.DisplayRequirementTiles;

  if (errorStatus) {
    return (
      <LeafTile hideBorder={true}>
        <ErrorTile
          clickFunction={refetchSsiData}
          errorStatus={errorStatus}
          isLoading={false}
          tealiumTrackId="Strong_Start_Incentive_Overview"
        />
      </LeafTile>
    );
  }

  if (ssiOverviewData.ShowDateRangeError) {
    return <ErrorPage errorType={ERROR_TYPE.INCENTIVE_UNAVAILABLE_FOR_PERIOD} isLoading={isLoading} />;
  }

  return (
    <main className="SSI">
      <PageTitleSection title={`${t("pageTitle", String(defaultContent["pageTitle"]))}`} />
      <div className="SSI_container">
        <section className="SSI__ container-split">
          <div className="SSI__section-padding SSI__container-row">
            <section className="SSI__banner">
              <SSIBannerTile
                incomeData={ssiOverviewData.IncomeData}
                selectedPeriod={selectedPeriod}
                isLoading={isLoading}
              />
            </section>
            {displayFullPage && (
              <section className="SSI__tracking">
                <TrackingProgressTile
                  isLoading={isLoading}
                  totalRequirements={ssiOverviewData.Requirements.TotalRequirements}
                  totalRequirementsMet={ssiOverviewData.Requirements.RequirementsMet}
                  requirementMetStatuses={ssiOverviewData.Requirements.RequirementMetStatuses}
                  id="Tracking Progress Tile"
                  clickAction={openEligibilityWindow}
                  trackingContent={t("progressTile", {
                    returnObjects: true,
                    defaultValue: defaultContent.progressTile,
                  })}
                />
              </section>
            )}
          </div>
        </section>

        {displayFullPage && (
          <div className="SSI__section-padding SSI__requirements">
            <div>
              <h2>{t("requirements.sectionHeader", String(defaultContent.requirements.sectionHeader))}</h2>
            </div>
            <div className="SSI__requirements-content">
              <RequirementTileMapper isLoading={isLoading} requirements={ssiOverviewData.Requirements} origin="ssi" />
            </div>
          </div>
        )}
        <section className="SSI__footer">
          <BonusInfoFooter />
        </section>
      </div>
      {displayFullPage && (
        <SSIEligibilityModal
          isLoading={isLoading}
          show={showEligiblityModal}
          onCloseClick={closeEligibilityWindow}
          eligibilityData={ssiOverviewData.EligibilityWindow}
        />
      )}
    </main>
  );
};
