import { Trans, useTranslation } from "react-i18next";
import { useAppSelector } from "../../../../store";
import defaultContent from "../../../../content/foundersPlatinumProgressTile";
import { LeafModal } from "../../../../common/leaf/LeafModal/LeafModal";
import { ErrorTile } from "../../../../common/components/ErrorTile/ErrorTile";
import LeafRectangleLoader from "../../../../common/leaf/LeafLoadingSkeleton/LeafRectangleLoader/LeafRectangleLoader";
import { AwardProgressBadge } from "../../../../common/components/AwardProgressBadge/AwardProgressBadge";
import { AWARD_PROGRESS } from "../../../../common/enums/award-progress-codes";
import { QUALIFICATION_STATUS } from "../../../../common/enums/incentive-enums";
import { TimeStamp } from "../../../../common/components/TimeStamp/TimeStamp";
import { GpvRequirementTile } from "./GpvRequirementTile/GpvRequirementTile";
import { VepvRequirementTile } from "./VepvRequirementTile/VepvRequirementTile";
import "./FoundersPlatinumProgress.scss";
import { FetchFoundersPlatinumProgressData } from "./FoundersPlatinumProgressDataLayer";
import { SpMonthsRequirementTile } from "./SpMonthsRequirementTile/SpMonthsRequirementTile";

type Props = {
  show: boolean;
  onCloseClick: Function;
  status: AWARD_PROGRESS;
};

export const FoundersPlatinumProgress = ({ show, onCloseClick, status }: Props) => {
  const { selectedPeriod } = useAppSelector((store) => store.period);
  const { t } = useTranslation(["foundersPlatinumProgressTile"]);
  const title = t("foundersPlatinumProgressLabel", defaultContent["foundersPlatinumProgressLabel"]).toString();

  const { isLoading, data, errorStatus, refetch } = FetchFoundersPlatinumProgressData(selectedPeriod);
  const { SpMonthsRequirement, GpvRequirement, VepvRequirement } = data;
  const { MonthsRequired: gpvTarget, AnnualGpvRequired } = GpvRequirement;
  const { MonthsRequired: vePvTarget, AnnualVepvRequired } = VepvRequirement;
  const { MonthsRequired: spMonthsTarget } = SpMonthsRequirement;

  return (
    <div className="Fpp">
      <LeafModal open={show} onCloseCallbackFn={onCloseClick} title={title}>
        <LeafRectangleLoader isLoading={isLoading} width="100%" height="100%">
          {errorStatus ? (
            <div className="Fpp__error-container">
              <ErrorTile
                clickFunction={refetch}
                errorStatus={errorStatus}
                isLoading={isLoading}
                tealiumTrackId="Founders_Platinum_Progress_Modal"
              />
            </div>
          ) : (
            <>
              <div className="Fpp__description">
                <Trans>
                  {t("headerLabel", defaultContent["headerLabel"], {})}
                  <span className="Fpp__bold-text"></span>
                </Trans>
              </div>
              <div className="Fpp__sub-description">
                <AwardProgressBadge status={status} />
                <TimeStamp dateFormat={"long"} />
              </div>
              <div className="Fpp__tiles-section">
                <SpMonthsRequirementTile spRequirementTileData={SpMonthsRequirement} />
                <GpvRequirementTile gpvRequirementData={GpvRequirement} />
                <VepvRequirementTile vepvRequirementData={VepvRequirement} />
              </div>
              <div className="Fpp__tooltip">
                <div className="Fpp__tooltip-title">
                  {t("tooltips.spMonths.tooltipTitle", defaultContent.tooltips.spMonths.tooltipTitle, {
                    spMonthsTarget,
                  })}
                </div>
                <div className="Fpp__tooltip-desc">
                  {t("tooltips.spMonths.tooltipDesc", defaultContent.tooltips.spMonths.tooltipDesc, {
                    spMonthsTarget,
                  })}
                </div>
              </div>
              <div className="Fpp__tooltip">
                <div className="Fpp__tooltip-title">
                  {t("tooltips.gpvMonths.tooltipTitle", defaultContent.tooltips.spMonths.tooltipTitle).toString()}
                </div>
                <div className="Fpp__tooltip-desc">
                  {t("tooltips.gpvMonths.tooltipDesc", defaultContent.tooltips.gpvMonths.tooltipDesc, {
                    gpvMethodMonthsRequired: gpvTarget,
                    gpvTarget: AnnualGpvRequired.toLocaleString(),
                  })}
                  <div>{t("disclaimerLabel", defaultContent["disclaimerLabel"]).toString()}</div>
                </div>
              </div>
              <div className="Fpp__tooltip">
                <div className="Fpp__tooltip-title">
                  {t("tooltips.vepvMonths.tooltipTitle", defaultContent.tooltips.spMonths.tooltipTitle).toString()}
                </div>
                <div className="Fpp__tooltip-desc">
                  {t("tooltips.vepvMonths.tooltipDesc", defaultContent.tooltips.vepvMonths.tooltipDesc, {
                    vepvMethodMonthsRequired: vePvTarget,
                    vePvTarget: AnnualVepvRequired.toLocaleString(),
                  })}
                </div>
              </div>
            </>
          )}
        </LeafRectangleLoader>
      </LeafModal>
    </div>
  );
};
