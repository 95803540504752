const content: any = {
  pageTitle: {
    performancePlusElite: "Performance Plus and Elite+",
    performancePlus: "Performance Plus+",
    performanceElite: "Performance Elite+",
  },
  bannerTile: {
    performancePlus: {
      notTracking: "It is no longer possible for you to achieve the Performance Plus Incentive+ for {monthYear}.",
      notEligible: "You were not eligible for the Performance Plus Incentive+ for {monthYear}.",
      earned: "Great Job! You are earning the Performance Plus Incentive+ for {monthYear}.",
      tracking: "Keep going to earn the Performance Plus Incentive+ for {monthYear}.",
      manuallyApproved: "The Performance Plus Incentive+ has been manually approved.",
      description:
        "After you reach the top of the Performance Bonus Schedule, the Performance Plus Incentive+ rewards you for growing your Ruby PV and building past full volume.",
    },
    performancePlusElite: {
      notTracking:
        "It is no longer possible for you to achieve the Performance Plus and Elite Incentive+ for {monthYear}.",
      notEligible: "You were not eligible for the Performance Plus and Elite Incentive+ for {monthYear}.",
      earned: "Great Job! You are earning the Performance Plus and Elite Incentive+ for {monthYear}.",
      tracking: "Keep going to earn the Performance Plus and Elite Incentive+ for {monthYear}.",
      manuallyApproved: "The Performance Plus and Elite Incentive+ has been manually approved.",
      description:
        "After you reach the top of the Performance Bonus Schedule, the Performance Plus and Elite Incentive+ rewards you for growing your Ruby PV and building past full volume.",
    },
    held: "Your award is on hold.",
    contactCustomerService: "If you require more information, please contact Customer Support for further details.",
    potentialEarnings: "Potential {monthYear} Earnings",
    incentiveCap: "Incentive is capped at {bonusCap}",
  },
  progressTile: {
    title: "Track your progress",
    progressLabel: "You've met <1>{current} of {needed}</1> requirements to earn this incentive.",
    performancePlusElite: {
      inProgressSubTitle:
        "Once all requirements are met, there is an opportunity to <1>level up</1> your incentive by achieving additional Ruby PV.",
      metSubTitle: "You have maximized the amount of incentive you can reach.",
    },
  },
  incentiveCalc: {
    sectionHeader: "How is this incentive calculated?",
    incentive: {
      base: "{month} Ruby BV",
      multiplierPerfPlus: "Performance Plus Incentive+ Multiplier",
      multiplierPerfElite: "Performance Plus and Elite Incentive+ Multiplier",
      total: "Current Total",
    },
  },
  multiplierMatrix: {
    title: "Multiplier Matrix",
    potentialMultiplier: "Potential Multiplier = {multiplier}",
    multiplier: "Multiplier",
    rubyPv: "Ruby PV",
    lessThanRubyPv: "Not All Qualification Requirements Achieved",
    amountRubyPv: "{volume} Ruby PV",
  },
  requirements: {
    sectionHeader: "How do I qualify? Here is the list of requirements.",
    personal: {
      title: "{volume} PPV",
      description: "",
    },
    basisCheckForSilverWithEq: {
      title: "Qualified Silver Producer",
      description: "Must qualify Silver Producer to earn this incentive.",
    },
    "ratioOfVcsToPpvPersonalOts(100%-OtsPercent(bonusAward552)": {
      title: "60% VCS",
      description: "",
    },
    rubyProgressTile: {
      rubyPV: "Ruby PV",
      performancePlusLbl: "{percent} Performance Plus",
      performanceEliteLbl: "{percent} Performance Elite",
      performancePlusEliteLbl: "{plusPercent} Performance Plus and {elitePercent} Performance Elite",
    },
  },
  rubyPvModal: {
    title: "What is Ruby PV?",
    description:
      "Ruby Volume includes Personal Volume plus Pass-up Volume from any downline who have not qualified at the {bonusLevel} Performance Bonus level for the month. Downline Platinum volume is not included, regardless of whether or not they have achieved {bonusLevel} Performance Bonus for the month.",
  },
};

export default content;
