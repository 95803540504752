import { useTranslation } from "react-i18next";
import { LeafModal } from "../../../../../common/leaf/LeafModal/LeafModal";
import LeafTable, { TableRow } from "../../../../../common/leaf/LeafTable/LeafTable";
import defaultContent from "../../../../../content/frontlineGrowth";
import "./PreviousFQModal.scss";
import { DetailsFqLeg } from "../../../../../common/interfaces/frontlineGroupGrowth";
import { MultiplierDetailsModal } from "../../MutliplierDetails";
import { renderLoadingOrError } from "../../MultiplierDetailsUtil";
import { IconBadge } from "../../../../../common/icons/IconBadge/IconBadge";

interface PreviousDetailsFQModal extends MultiplierDetailsModal {
  previousFqLegs: DetailsFqLeg[];
  previousFQs: string;
}

export const PreviousFQModal = ({
  previousFqLegs,
  previousFQs,
  displayModal,
  toggleModal,
  isLoading,
  refetch,
  errorStatus,
}: PreviousDetailsFQModal) => {
  const { t } = useTranslation(["frontlineGrowth"]);

  const rows = previousFqLegs.map((leg: DetailsFqLeg): TableRow => {
    const { name, aboNo, newPlatLegStatus, ytdQualCount } = leg;
    const displayName = !name ? `${t("confidentialUser", defaultContent["confidentialUser"])}` : name;
    const firstCell = (
      <span className="MultiplierDetails__cell-container">
        <p className="MultiplierDetails__cell-container--name">{displayName}</p>
        <div className="MultiplierDetails__cell-container--abo-container">
          <p className="MultiplierDetails__cell-container--abo">#{aboNo.toString()}</p>
          <span>{newPlatLegStatus && <IconBadge />}</span>
        </div>
      </span>
    );

    return {
      row: [
        { value: firstCell, columnAlign: "left" },
        { value: ytdQualCount, columnAlign: "right" },
      ],
    };
  });

  const renderModalBody = () => {
    return (
      <div className="PreviousFQModal__body">
        <LeafTable
          header={[]}
          rows={rows}
          columnAlign="center"
          hideHeader={true}
          rowHeight="2.5rem"
          headerStyle={"default"}
        />
      </div>
    );
  };

  return (
    <div className="CurrentFQModal">
      <LeafModal
        open={displayModal}
        onCloseCallbackFn={toggleModal}
        title={`${t("multiplierDetails.previousPy", defaultContent.multiplierDetails.previousPy)}`}
        subtitle={`${t("previousFqs", defaultContent.previousFqs, { fqs: previousFQs })}`}
      >
        {!isLoading && !errorStatus ? (
          <>{renderModalBody()}</>
        ) : (
          <>{renderLoadingOrError(isLoading, errorStatus, refetch, "Previous_FQ_Modal")}</>
        )}
      </LeafModal>
    </div>
  );
};
