import { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../store";
import { ANNUAL_INCENTIVES_AWARD_CODES } from "../common/constants/market-constants";
import { ANNUAL_INCENTIVES_PATHNAMES, ROUTE_PATHS } from "../common/enums/routing-enums";
import { AWARD_CODES } from "../common/enums/award-codes";
import { ShellConfigApiResponse } from "../reducers/model/shellConfig";
import { setShellLoading } from "../reducers/boot";
import { useGetShellConfigQuery } from "./getShellConfigAPI";
import { useGetLastestActivePeriodQuery } from "./getLatestActivePeriod";

export const ShellConfigData = ({ pathname }: { pathname: string }) => {
  const { selectedPeriod: bonusPeriod } = useAppSelector((state) => state.period);
  const { user, configuration } = useAppSelector((state) => state.boot);
  const dispatch = useAppDispatch();
  const faaEligibilityWindow = configuration.faaEligibilityWindow;
  const {
    data: shellConfigData,
    isLoading: isQueryLoading,
    isFetching,
    isError,
  } = useGetShellConfigQuery(
    {
      baseUrl: configuration.shellLambdaUrlBase,
      affAbo: `${user.aff}-${user.abo}`,
      period: bonusPeriod,
      faaWindow: faaEligibilityWindow,
      optionalPeriodRange: configuration.optionalPeriodRange,
    },
    { skip: !user.aff },
  );

  const getAnnualPeriods = () => {
    return (
      shellConfigData?.dates.availableYears.map((period) => Number(`${period.display.toString().slice(0, 4)}08`)) || [
        Number(`${bonusPeriod.toString().slice(0, 4)}08`),
      ]
    );
  };

  const getAvailableAnnualAwards = () => {
    const nums = shellConfigData?.eligibleIncentives
      .filter(({ awardNumber }) => ANNUAL_INCENTIVES_AWARD_CODES.includes(awardNumber))
      .map((i) => i.awardNumber);
    return nums || [0];
  };
  const {
    data: latestPeriodData,
    isLoading: isLatestPeriodLoading,
    isFetching: isLatestPeriodFetching,
    isError: isLatestPeriodError,
  } = useGetLastestActivePeriodQuery(
    {
      affAbo: `${user.aff}-${user.abo}`,
      awardCodes: getAvailableAnnualAwards(),
      annualPeriods: getAnnualPeriods(),
      latestFiscalYear: configuration.latestFiscalYearAvailable,
    },
    { skip: !shellConfigData || isQueryLoading || isFetching },
  );

  const [isLoading, setIsLoading] = useState(true);
  const [errorStatus, setErrorStatus] = useState(false);
  const [data, setData] = useState<any>(null);
  const checkForAnnualComingSoon = useCallback(
    (shellConfig: ShellConfigApiResponse) => {
      const selectedIncentive = shellConfig.eligibleIncentives.slice().find((incentives) => {
        const findKey = (Object.keys(ROUTE_PATHS) as (keyof typeof ROUTE_PATHS)[]).find((key) => {
          return ROUTE_PATHS[key] === pathname;
        });

        const awardCodesArr: any = AWARD_CODES;
        return findKey
          ? (incentives.programActive || incentives.comingSoon) && awardCodesArr[findKey] === incentives.awardNumber
          : false;
      });

      // logic to set coming soon for annual performance dashboard, checking all the annual incentives if any one of them is coming soon
      if (selectedIncentive && selectedIncentive.awardNumber === AWARD_CODES.ANNUAL_PERFORMANCE) {
        const filteredincentives = shellConfig.eligibleIncentives
          .slice()
          .filter((incentive) => ANNUAL_INCENTIVES_AWARD_CODES.includes(incentive.awardNumber));
        selectedIncentive.comingSoon = filteredincentives.some((incentive) => incentive.comingSoon);
      }

      const isComingSoon = selectedIncentive ? selectedIncentive?.comingSoon : false;
      let latestActivePeriod;

      if (isComingSoon) {
        if (selectedIncentive && selectedIncentive.awardNumber && latestPeriodData) {
          const { latestActiveAnnualPeriod = "" } = latestPeriodData[selectedIncentive.awardNumber] || {};
          latestActivePeriod = latestActiveAnnualPeriod;
        }
      }

      return { isComingSoon, latestActivePeriod };
    },
    [latestPeriodData, pathname],
  );

  // if loading or fetching set loading flag to true
  useEffect(() => {
    if (isQueryLoading || isFetching || isLatestPeriodLoading || isLatestPeriodFetching) {
      setIsLoading(true);
      dispatch(setShellLoading({ isLoading: isQueryLoading, isFetching }));
    }
  }, [isQueryLoading, isFetching, isLatestPeriodLoading, isLatestPeriodFetching]);

  useEffect(() => {
    if (isError) {
      setErrorStatus(true);
      dispatch(setShellLoading({ isLoading: false, isFetching: false }));
    }
  }, [shellConfigData, isError]);

  //once loading has finished and data is resolved, save to state and set loading to false
  useEffect(() => {
    if (
      !isQueryLoading &&
      !isFetching &&
      !isError &&
      !isLatestPeriodError &&
      shellConfigData &&
      !isLatestPeriodLoading &&
      !isLatestPeriodFetching
    ) {
      if (ANNUAL_INCENTIVES_PATHNAMES.includes(pathname)) {
        const comingSoonData = checkForAnnualComingSoon(JSON.parse(JSON.stringify(shellConfigData)));
        setData({
          ...shellConfigData,
          isComingSoon: comingSoonData.isComingSoon,
          latestActivePeriod: comingSoonData.latestActivePeriod,
        });
        setIsLoading(false);
        dispatch(setShellLoading({ isLoading: false, isFetching: false }));
      } else {
        setData({ ...shellConfigData, isComingSoon: null, latestActivePeriod: null });
        setIsLoading(false);
        dispatch(setShellLoading({ isLoading: false, isFetching: false }));
      }
      setErrorStatus(false);
      dispatch(setShellLoading({ isLoading: false, isFetching: false }));
    }
  }, [
    shellConfigData,
    isError,
    isLatestPeriodError,
    isQueryLoading,
    isFetching,
    bonusPeriod,
    pathname,
    checkForAnnualComingSoon,
  ]);

  return { isLoading, data, errorStatus };
};
