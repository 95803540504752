import { createApi } from "@reduxjs/toolkit/query/react";
import { CallResult } from "../common/interfaces/accApiWorkspace";
import { QUALIFICATION_STATUS } from "../common/enums/incentive-enums";
import { Progress, Requirements } from "../common/interfaces/coreplusIncentives";
import { REQUIREMENT_NAME } from "../common/enums/requirements";
import { dynamicBaseQuery } from ".";

export type PPERubyRequirements = {
  Name: REQUIREMENT_NAME;
  Met: boolean;
  Percent: Progress;
  Volume: Progress;
};

export interface PPERubyRequirement {
  Name: string;
  Met: boolean;
  Percent: Progress;
  Volume: Progress;
  Multiplier: string;
}

export interface PPERequirements extends Requirements {
  PPERubyRequirements: PPERubyRequirement[];
}

export interface PPEIncomeData {
  PotentialEarnings: string;
  IncentiveCap: string;
  Status: QUALIFICATION_STATUS;
}

export interface PPEIncentiveCalculation {
  RubyBV: string;
  IncentiveMultiplier: string;
  Total: string;
}

interface MatrixRowItem {
  Value: string;
  ContentKey: string;
  IsHighlighted: boolean;
}

export interface PPEMatrix {
  Rows: MatrixRowItem[][];
}

export interface PPEOverviewResponse {
  IncomeData: PPEIncomeData;
  IncentiveCalculation: PPEIncentiveCalculation;
  MultiplierMatrix: PPEMatrix;
  Requirements: PPERequirements;
  MaxBonusPercent: string;
  ShowDateRangeError: boolean;
  HideRequirements: boolean;
  CallResults: CallResult[];
  Error: any;
}

interface PPERequestParams {
  abo: string;
  aff: string;
  locale: string;
  period: string;
  isoCountryCode: string;
  isoCurrencyCode: string;
}

export const performancePlusEliteAPI = createApi({
  reducerPath: "performancePlusEliteAPI",
  baseQuery: dynamicBaseQuery,
  endpoints: (builder) => ({
    getPPEOverviewAPI: builder.query<PPEOverviewResponse, PPERequestParams>({
      query: (params) => {
        const { abo, aff, locale, period, isoCountryCode, isoCurrencyCode } = params;
        const queryParams = new URLSearchParams({
          Abo: abo,
          Aff: aff,
          Locale: locale,
          Period: period,
          IsoCountryCode: isoCountryCode,
          IsoCurrencyCode: isoCurrencyCode,
        });

        return `/incentives-v2/api/ppe/overview?${queryParams}`;
      },
      extraOptions: { urlBase: "apiBaseUrl" },
    }),
  }),
});

export const { useGetPPEOverviewAPIQuery } = performancePlusEliteAPI;
