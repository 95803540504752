import "./MonthlyBonusBreakdown.scss";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { BonusBreakdownTile } from "../../../BonusBreakdownTile/BonusBreakdownTile";
import defaultContent from "../../../../content/bonusBreakdown";
import LeafRectangleLoader from "../../../../common/leaf/LeafLoadingSkeleton/LeafRectangleLoader/LeafRectangleLoader";
import LeafTile from "../../../../common/leaf/LeafTile/LeafTile";
import { ErrorTile } from "../../../../common/components/ErrorTile/ErrorTile";
import { useAppSelector } from "../../../../store";
import {
  BonusBreakdownLineData,
  BonusBreakdownSimpleLineData,
} from "../../../../common/interfaces/monthlyPerformanceDash";
import { AWARD_CODES } from "../../../../common/enums/award-codes";
import { getRoutePathForAwardCode } from "../../../../common/util/navigationUtil";
import { storedLocale } from "../../../impersonation/util";
import { useGetPerfBonusDetailsQuery } from "../../../../services/monthyPerformanceDashboardAPI";
import { ROUTE_PATHS } from "../../../../common/enums/routing-enums";
import PersonalBonusModal from "../MonthlyPerformanceTotalModals/PersonalBonusModal/PersonalBonusModal";
import DifferentialBonusModal from "../MonthlyPerformanceTotalModals/DifferentialBonusModal/DifferentialBonusModal";
import { monthlyBonusBreakdownMap } from "./MonthlyBonusBreakdownMap";
import { FetchBonusBreakdownData } from "./MonthlyBonusBreakdownDataLayer";

export const MonthlyBonusBreakdown = () => {
  const { selectedPeriod } = useAppSelector((state) => state.period);
  const { t } = useTranslation(["bonusBreakdown"]);
  const { menuConfig } = useAppSelector((state) => state.boot.shellConfig);
  const showBronzeIncentives = useAppSelector((store) => store.boot.configuration.navigation.showBronzeIncentives);
  const [isPerfBonusPersonalModal, setPerfBonusPersonalModal] = useState(false);
  const [isDiffBonusPersonalModal, setDiffBonusPersonalModal] = useState(false);
  const { configuration, user } = useAppSelector((state) => state.boot);

  const {
    data: bonusData,
    isFetching,
    isError,
  } = useGetPerfBonusDetailsQuery({
    baseUrl: configuration.monthlyDashboardApiUrl,
    affAbo: `${user.aff}-${user.abo}`,
    period: selectedPeriod,
    locale: storedLocale(),
    countryCode: user.isoCountryCode,
  });

  const renderBonusBreakdown = () => {
    const {
      bonusBreakdownData,
      isLoading,
      errorStatus,
      refetch: refetchBousBreakdownData,
    } = FetchBonusBreakdownData(selectedPeriod);

    if (isLoading) {
      return (
        <div className="bbt-loader-container">
          <LeafRectangleLoader isLoading={isLoading} numRows={1} width="100%" height="25rem" margin={"0 0 0.5rem 0"} />
          <div className="bbt-loader-container__bottom-line">
            <LeafRectangleLoader
              isLoading={isLoading}
              numRows={1}
              width="12rem"
              height="1.5rem"
              margin={"0 0 0.5rem 0"}
            />
            <LeafRectangleLoader
              isLoading={isLoading}
              numRows={1}
              width="7rem"
              height="1.5rem"
              margin={"0 0 0.5rem 0"}
            />
          </div>
        </div>
      );
    }

    if (errorStatus) {
      return (
        <ErrorTile
          clickFunction={refetchBousBreakdownData}
          errorStatus={errorStatus}
          isLoading={isLoading}
          tealiumTrackId="Bonus_Breakdown"
        />
      );
    }

    const responseData = bonusBreakdownData.BonusAmount;
    const subGroup = [AWARD_CODES.PERFPRMANCE_BONUS_PERSONAL, AWARD_CODES.PERFPRMANCE_BONUS_DIFFERENCIAL];
    const order = monthlyBonusBreakdownMap.map(({ awardCode }) => awardCode);
    const group: BonusBreakdownSimpleLineData[] = [];

    const getLink = (awardCode: number) => {
      if (showBronzeIncentives === true) {
        switch (awardCode) {
          case AWARD_CODES.PATH_TO_BRONZE:
            return ROUTE_PATHS.NEW_PATH_TO_BRONZE;
          case AWARD_CODES.BRONZE_FOUNDATION:
            return ROUTE_PATHS.NEW_BRONZE_FOUNDATION;
          case AWARD_CODES.BRONZE_BUILDER:
            return ROUTE_PATHS.NEW_BRONZE_BUILDER;
        }
      }
      return getRoutePathForAwardCode(menuConfig, awardCode);
    };

    // setting up modal for Performance Bonus
    const getModal = (awardCode: number, modalState: boolean) => {
      switch (awardCode) {
        case AWARD_CODES.PERFPRMANCE_BONUS_PERSONAL:
          return setPerfBonusPersonalModal(modalState);
        case AWARD_CODES.PERFPRMANCE_BONUS_DIFFERENCIAL:
          return setDiffBonusPersonalModal(modalState);
        default:
          return;
      }
    };
    const bonusTotal: BonusBreakdownLineData[] = [];
    const renderData = responseData
      .map((item: { awardCode: number; bonusAmount: string }) => {
        const mapItem = monthlyBonusBreakdownMap.find((element) => element.awardCode === item.awardCode);
        const title = mapItem ? mapItem.name : "";
        const result: BonusBreakdownLineData = {
          awardCode: item.awardCode,
          incentiveTitle: t(title, defaultContent[title]),
          tealiumTag: defaultContent[title].replace("+", ""),
          amount: item.bonusAmount,
          link: getLink(item.awardCode),
          modalFn: (subGroup.includes(item.awardCode)
            ? () => getModal(item.awardCode, true)
            : undefined) as unknown as Function,
        };
        if (item.awardCode === AWARD_CODES.PERFORMANCE_BONUS) {
          result.group = group;
        }
        if (subGroup.includes(item.awardCode)) {
          group.push(result);
          bonusTotal.push(result);
        }
        return result;
      })
      .filter((item) => !subGroup.includes(item.awardCode))
      .sort((a, b) => order.indexOf(a.awardCode) - order.indexOf(b.awardCode));

    const performanceBonusTotal = bonusTotal.find((item) => item.awardCode === AWARD_CODES.PERFPRMANCE_BONUS_PERSONAL);
    const differentialBonusTotal = bonusTotal.find(
      (item) => item.awardCode === AWARD_CODES.PERFPRMANCE_BONUS_DIFFERENCIAL,
    );
    return (
      <>
        <BonusBreakdownTile
          title={t("bonusBreakdown", defaultContent["bonusBreakdown"])}
          subTitle={t("monthlyBonus", defaultContent["monthlyBonus"])}
          bonusSubTotalTitle={t("monthlyBonusSubtotal", defaultContent["monthlyBonusSubtotal"])}
          bonusSubTotalValue={bonusBreakdownData.BonusAmountSubTotal}
          bonusBreakdown={renderData}
        />
        <PersonalBonusModal
          openModal={isPerfBonusPersonalModal}
          onCloseModal={() => getModal(AWARD_CODES.PERFPRMANCE_BONUS_PERSONAL, false)}
          performanceData={{ ...bonusData }}
          performanceTotal={{ ...performanceBonusTotal }}
          isFetching={isFetching}
          isError={isError}
        />
        <DifferentialBonusModal
          openModal={isDiffBonusPersonalModal}
          onCloseModal={() => getModal(AWARD_CODES.PERFPRMANCE_BONUS_DIFFERENCIAL, false)}
          performanceData={{ ...bonusData }}
          performanceTotal={{ ...differentialBonusTotal }}
          isFetching={isFetching}
          isError={isError}
        />
      </>
    );
  };

  return <LeafTile>{renderBonusBreakdown()}</LeafTile>;
};
